var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3 mx-3"},[_c('div',{staticClass:"card smcard",class:{
      'pcard-dark': _vm.nightMode,
      pcard: !_vm.nightMode,
      'bg-dark3': _vm.nightMode,
    }},[_c('div',{staticStyle:{"height":"180px"}},[_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.certificate.pictures[0].img,"alt":"Card image cap"}})]),_c('div',{staticClass:"card-body pborder-top text-center"},[_c('h5',{staticClass:"title2"},[_vm._v(_vm._s(_vm.certificate.name))]),_c('div',[_c('p',{staticClass:"title3 m-10 pb-2 pheight pt-1",domProps:{"innerHTML":_vm._s(
            _vm.certificate.description.length > 100
              ? _vm.certificate.description.substring(0, 105) + '...'
              : _vm.certificate.description
          )}})]),_c('div',{staticClass:"text-center mt-2"},[_c('button',{staticClass:"btn-sm btn btn-outline-secondary no-outline",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showModal.apply(null, arguments)}}},[_vm._v(" Full View ")]),(_vm.certificate.visit)?_c('button',{staticClass:"btn-sm btn btn-outline-secondary no-outline ml-4",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.open(_vm.certificate.visit)}}},[_vm._v("Show credential")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }