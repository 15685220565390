let info = {
  name: "Vishal Barvaliya",
  logo_name: "Vishal",
  flat_picture: require("./src/assets/potrait.jpg"),
  config: {
    use_cookies: true,
    navbar: {
      blur: false
    }
  },
  description:
    "Vishal Barvaliya I define myself as a Data Engineer who also harvest Azure Cloud!. Always curious to learn and discuss about technologies. I have proficiency in different Big Data Technologies, Frameworks and have worked a lot on them as well to find most optimum data solutions.",
  links: {
    linkedin: "https://www.linkedin.com/in/vishalbarvaliya/",
    github: "https://github.com/barvaliyavishal",
    resume: "https://github.com/barvaliyavishal/Resume/blob/main/Vishal_Resume.pdf"
  },
  education: [
    {
      name: "Lambton College",
      place: "Toronto, Canada",
      date: "Jan, 2022 - Aug, 2023",
      degree: "Big Data Analytics",
      gpa: "3.7/4.0",
      description:"",
      skills: [
        "Big Data",
        "Hadoop",
        "Spark",
        "Hive",
        "Pig",
        "Sqoop",
        "Data Structure",
        "Algorithms",
        "System Design",
        "SQL",
        "Python",
        "Azure"
      ]
    },
    {
      name: "MKBU University",
      course:"Bachelor of Computer Application",
      place: "Gujarat, India",
      date: "June, 2016 - Apr, 2019",
      degree: "Bachelor Of Computer Application",
      gpa: "8.0/9",
      description:"",
      skills: [
        "OOP",
        "MYSQL",
        "Github",
        "Bit of C/C++",
        "HTML",
        "CSS",
        "Python",
        "Java",
        "SQL",
        "Oracle",
        "PHP"
      ]
    }
  ],
  experience: [

    {
      name: "TD Bank",
      place: "Toronto, Canada",
      position: "Future Data Engineer Associate",
      date: "From May, 2023",      
      description:"",
      skills: [
        "Python",
        "SQL",
        "PySpark",
        "Azure cloud",
        "Hive",
        "Data Modeling",
        "Data Warehousing",
        "ETL"
      ]
    },
    {
      name: "Mundelbulb Technologies",
      place: "Gujarat, India",
      position: "Data Engineer Associate",
      date: "Sept 2021 - Nov 2021",      
      description:"",
      
      skills: [
        "Spark",
        "Hadoop",
        "SQL",
        "Python",
        "Databricks",
        "Data Factory",
        "Data Modeling",
        "Azure",
        "ETL"

      ]
    }
  ],
  skills: [
    {
      title: "Languages",
      info: [
        "Python",
        "Scala",
        "SQL"
      ],
      icon: "fa fa-code"
    },
    {
      title: "Big Data Frameworks",
      info: [
        "Spark",
        "Hadoop",
        "Hive",
        "Sqoop"

      ],
      icon: "fa fa-cubes"
    },
    {
      title: "NoSQL Databases",
      info: ["Cassandra"],
      icon: "fas fa-laptop-code"
    },
  
    {
      title: "Azure Cloud",
      info: ["Databricks","Data Factory", "Synapse Analytics", "SQL Database", "Data Lake Storage gen2", "Blob Storage", "Active Directory"],
      icon: "fa fa-cloud"
    },

    {
      title: "SQL Databases",
      info: ["MySQL", "PostGreSQL"],
      icon: "fa fa-database"
    },


    {
      title: "Orchestration Tools",
      info: [
        "Airflow",
        "Azure Data Factory"
      ],
      icon: "fas fa-tools"
    },

    {
      title: "",
      info: [
      ],
      icon: ""
    },

    {
      title: "Other",
      info: [
        "Data Warehouse",
        "Data Modeling",
        "strong verbal and written communication",
        "ETL",
        "ELT",
        "PowerBI"
      ],
      icon: "fa fa-feather"
    },

    {
      title: "",
      info: [
      ],
      icon: ""
    },

  ],
  certificates: [

    {
      name: "Microsoft Certified: Data Engineer",
      pictures: [
        {
          img: require("./src/assets/certificates/DP-203/dp203.png")
        }
      ],
      technologies: [],
      category: "",
      visit: "https://www.credly.com/badges/89e129be-40b9-455a-8639-52d4a761718d/public_url",
      description:""
    },

    {
      name: "2X Databricks Certified Databricks Certified: Apache Spark Developer",
      pictures: [
        {
          img: require("./src/assets/certificates/spark/spark.png")
        }
      ],
      technologies: [],
      category: "",
      github: "https://github.com/Jaydip/grocery-listing-react",
      date: "",
      visit: "https://credentials.databricks.com/b3f12d5a-55df-4744-b8ad-53efa6838d7a",
      description:  "",
    },
    {
      name: "Databricks Lakehouse Fundamentals",
      pictures: [
        {
          img: require("./src/assets/certificates/lakehouse/badge.png")
        }
      ],
      technologies: [],
      category: "",
      github: "https://github.com/Jaydip/grocery-listing-react",
      date: "",
      visit: "https://credentials.databricks.com/1eaaaf01-0287-4e25-973e-4ec6d41bbfa8",
      description:  "",
    },
    {
      name: "Astronomer Certification for Apache Airflow Fundamentals",
      pictures: [
        {
          img: require("./src/assets/certificates/airflow/badge.png")
        }
      ],
      technologies: [],
      category: "",
      github: "https://github.com/Jaydip/grocery-listing-react",
      date: "",
      visit: "https://www.credly.com/badges/1d6c3de2-fdb4-43d1-a135-0ebfeb025997/public_url",
      description:  "",
    }
  ],
   recommendations: [

    {
      title:"Vishal is a very talented programmer with whom I have worked for a while and he is dedicated, hard-working, driven, and fun to work with. Has good catching power and tries to thoroughly go through the concepts. A good team player who will do more than he is supposed to.",
      author: "Manish Kushwaha",
      position: "Data Engineer",
      company: "Mundelbulb Technologies",
      location: "jaipur, India"
    },

    {
      title:"Any organization would be lucky to have Vishal as their Engineer.This is the person who has become for me an ideal for learning area and any tech stack like Hadoop, Spark, Python, SQL, even at he is proficient at Data Structure & Algorithm & Problem solving. Vishal earns my highest recommendation.",
      author: "Solanki Mayur",
      position: "MCA Student",
      company: "LD College ",
      location: "India"
    },
    
    {
      title:"I had the pleasure of working with Vishal in the team over multiple projects during my graduation. He is an exceptional person and passionate about his work. He is a very keen learner which supports his agility to blend in the company's work and culture. Jaydip would be a valuable asset to any company.",
      author: "Kartik Algoter",
      position: "Interior Design Associate",
      company: "Asian Paints",
      location: "Gujarat, India"
    },
    
    {
      title:" I’m amazed by Vishal's ability to a Data Engineering skill, a skill he uses to his benefit to clients and colleagues.He is always interested in doing something out of his comfort zone and is open to learning new technologies like Azure Databricks, Pyspark, Hadoop, Hive, and many other big data tech stacks. He is a highly focused person as well as analytical and can add to any team he is a part of. His energy to make things happen was contagious and it helped me achieve great goals. One of the best friends I have for sure. I have learned a lot from Vishal ",
      author: "Dharm Kakadiya",
      position: "Java Developer",
      company: "St. Clair",
      location: "Toronto, Canada"
    },
    
    {
      title:"I met Vishal during my graduation five years back and it was pleasure to have a mentor like him . He is amazing at his work. He knows his way around people, he is good with Data Engineering, does whatever it takes to help needy people and get things done. He is very responsible and detail-oriented that makes him stand out. He keeps himself updated with the trending technologies and also shares them with me as well.",
      author: "Nikhil Muliyasiya",
      position: "Android Developer",
      company: "",
      location: "Gujarat, India"
    },
    
    {
      title:"We’ve joined our hands on several projects, and Vishal is one of the best people I had as a partner. I highly recommend his expertise to any person looking for a Data Engineer. He is the most profound person I have met, and his ability to tackle any problem is remarkable and with a warm smile. Vishal would become an appreciated member of any team.",
      author: "Tushar Joshi",
      position: "Full Stack Developer",
      company: "Centillion Softtech Private LTD.",
      location: "Bhavnagar, Gujarat, India"
    },
    
    {
      title:"Vishal & I have been in close contact for more than 5 years. He has a deep understanding and has good analytical skills. He has worked on many data engineering projects. The most important thing about him that I admire the most is that he is always ready to learn something new. Apart from his professional achievements, Vishal is a joyful person & a good friend to me.Hence, I firmly believe that Vishal would be a good addition to any organization or team.",
      author: "Naresh Parmar",
      position: "Big Data Engineer",
      company: "",
      location: "India"
    },
    
    {
      title:"We have known each other since five years ago and I am proud to say that I rarely come across real talents who stand out like Vishal. Dedicated, Good listener, Hardworking, Good Learning appetite are the attributes one will observe while working with him, I had the pleasure of working with Vishal, I highly recommend him and wish him a great career ahead.",
      author: "Rohan Makwana",
      position: "Dot Net Developer",
      company: "Addact",
      location: "Ahmedabad, Gujarat, India"
    },

    {
      title:"I have known Vishal for over 5 years. We met in college and had been friends ever since. I have known him as an intelligent, hardworking, and responsible individual.Vishal is committed to his community and is always looking for ways to support the people around him. I know him to be trustworthy, reliable, and easy-going. He is an avid reader and an extremely knowledgeable individual whose organizational and Data Science skills would enable him to become a valuable Engineer at any company.",
      author: "Vishal Gajipara",
      position: "Network Engineer",
      company: "Wipro",
      location: "Gujarat, India"
    },
    
    {
      title:"We are not in the same industry, but since the 12th-grade time, I know him. The way he thinks when it comes to the technical perspective that's lit. He always learns, loves, and endorse computer jargon. He has been mentoring for technical ideas, various projects, geeky notions to tons of people, and some of them are his seniors, also. I'm proud of having him on my best friend list like Vishal. His wit nature can make any tough work environment easy. In addition to that, the best thing I like about Vishal is his helping hands to any needy person. He will be a great asset for any needed company.",
      author: "Parthik Dhameliya",
      position: "Co-Founder at HoneyPath, Co-Founder at Phoenix Pharmacy",
      company: "HoneyPath, Phoenix",
      location: "Bhavnagar, Gujarat, Canada"
    },
    
    {
      title:"We have known each other for over 5 years now. Vishal Barvaliya and I went to the same college and I had the pleasure of taking a few classes with him and seeing how brilliant he is.He is an exceptional learner and his knowledge in Data Engineering is very extensive. His ability to focus helped him to become a Great Data Engineer. I love his positive attitude and he is my go-to person when I need advice.",
      author: "Paras Gadhiya",
      position: "Big Data Engineer ",
      company: "ProcDNA",
      location: "Delhi, India"
    },
    
    {
      title:"Vishal is enthusiastic and passionate personnel and Data Engineering genius as he has a vast amount of knowledge in ETL pipelines, Apache Hadoop, Apache Spark, Apache Hive, SQL, Azure Databricks, and many other Big Data tech stacks. He has an outstanding network and holds many certifications in Data Engineering. He is a professional, helpful, and positive person. It is a pleasure and honor to recommend Vishal to anyone who wants to hire him. He is truly a gem.",
      author: "Ravi Makwana",
      position: "Data Science Engineer",
      company: "",
      location: "Gujarat, India"
    },
    
    {
      title:"Vishal is an exceptionally strong and professional Big Data Engineer. He combines strong technical skills and know-how with a can-do attitude. Vishal is would be an asset on any Big Data / Data Engineer / Data Warehousing / Business Intelligence project. The best thing I really really like about Vishal is, he can learn and grasp any new concept within few time and that can be really really helpful in plethora of situations.",
      author: "jaydip Barvaliya",
      position: "Senior Software Engineer",
      company: "Cognizant",
      location: "Toronto, Canada"
    },
    
    {
      title:"We met many people every day, but few people leave the mark. For me that's Vishal. I can proudly say that Vishal Barvaliya is my friend and brother. I know him sins last couple of years and in that he went through a metamorphosis. From a typical developer to a Professional Big Data Engineer he transformed himself in a great way. Under his guidance, I am learning programming and data science that help me in my career as a bioinformatician. It is a pleasure and honor to recommend Vishal to anyone who wants to work with him. He is truly a gem.",
      author: "Piyush H Vaghela",
      position: "Pharmacy Assistant",
      company: "Loblaw",
      location: "Scarborough, Canada"
    },
    
    {
      title:"Any organization would be lucky to have Vishal as their Engineer.He is an exceptionally strong and professional ETL/Big Data developer. Vishal would be an asset on any Big Data / ETL / Hadoop / Spark / Data Warehousing / SQL / Business Intelligence project.He is really proficient at Data Structure & Algorithm & Problem solving. Vishal earns my highest recommendation.",
      author: "Vishal Rajai",
      position: "Software Developer",
      company: "Anthology",
      location: "Bangalore, India"
    }
    ]
};

export default info;



