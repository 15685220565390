let config = {
  emailjs: {
    serviceID: "gmail_service_id",
    templateID: "gmail_template_id",
    userID: "IygQ7YJg6Whkabhry"
  }
};



export default config;
